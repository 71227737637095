import type { ConfigurationResponse } from '~/types/__generated__'

export function useApiConfiguration() {
  const { PAGE_POPULATE, CONTENT_POPULATE } = useApiPage()
  const client = useStrapiClient()

  async function getConfiguration() {
    const { data } = await client<ConfigurationResponse>('/configuration', {
      method: 'GET',
      params: {
        populate: [
          'socials',
          'home',
          ...PAGE_POPULATE.map((pop) => `home.${pop}`),
          ...CONTENT_POPULATE.map((pop) => `home.${pop}`),
          'notFound',
          ...PAGE_POPULATE.map((pop) => `notFound.${pop}`),
          ...CONTENT_POPULATE.map((pop) => `notFound.${pop}`),
        ],
      },
    })
    return data
  }

  return {
    getConfiguration,
  }
}
