import type { PageListResponse } from '~/types/__generated__'

const PAGE_POPULATE = [
  'parent',
  'parent.parent',
  'parent.parent.parent',
  'meta',
  'meta.image',
  'meta.socials',
  'meta.socials.image',
]

const CONTENT_POPULATE = [
  'content',
  'content.visual',
  'content.visuals',
  'content.buttons',
  'content.partners',
  'content.partners.logo',
  'content.link',
  'content.items',
  'content.items.icon',
  'content.items.link',
  'content.items.visual',
  'content.items.items',
  'content.items.button',
  'content.button',
  'content.posts',
  'content.posts.image',
  'content.form',
  'content.form.formField',
  'content.form.redirect',
]

export function useApiPage() {
  const route = useRoute()
  const configurationStore = useConfigurationStore()
  const client = useStrapiClient()
  const { getSlugFilter } = useUrl()

  async function getPageForRoute() {
    let filters = {}
    if (route.params.slug === '') {
      if (configurationStore.configuration?.home) {
        return configurationStore.configuration.home
      } else {
        filters = {
          slug: { $eq: 'home' },
        }
      }
    } else {
      filters = getSlugFilter(route)
    }

    const response = await client<PageListResponse>('/pages', {
      method: 'GET',
      params: {
        filters,
        populate: [...PAGE_POPULATE, ...CONTENT_POPULATE],
      },
    })

    return response.data?.[0]
  }

  return {
    getPageForRoute,
    CONTENT_POPULATE,
    PAGE_POPULATE,
  }
}
