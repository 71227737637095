export const useConfigurationStore = defineStore('configuration', () => {
  const apiConfiguration = useApiConfiguration()
  const configuration = ref({})

  async function getConfiguration() {
    const data = await apiConfiguration.getConfiguration()
    configuration.value = data
  }

  return {
    configuration,
    getConfiguration,
  }
})
